import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserPermission } from '../auth/auth.models';

@Injectable({ providedIn: 'root' })
export class SecurityService {
    loadUserName(objectId: string): Observable<any> {
        return this.http.get<any>(`https://graph.microsoft.com/v1.0/users/${objectId}`);
    }

    constructor(private http: HttpClient) {}

    searchUsers(txt: string, hasDirectoryReadAll : boolean): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('ConsistencyLevel', 'eventual');

        let filter = '';
        const v = encodeURIComponent(txt.trim());
        if (hasDirectoryReadAll) {
            filter = `startswith(displayName,'${v}') or startswith(surname,'${v}') or startswith(jobTitle,'${v}')`;
        } else {
            filter = `startswith(displayName,'${v}') or startswith(surname,'${v}')`;
        }
       
        const url = `https://graph.microsoft.com/v1.0/users?$count=true&$filter=${filter}&$orderBy=displayName`;
        return this.http.get<any>(url, { headers });
    }

    saveAdmin(objectId: string, userName: string): Observable<UserPermission[]> {
        return this.http.post<UserPermission[]>(
            `/api/security/adminUser/${objectId}?username=${encodeURIComponent(userName)}`,
            { objectId }
        );
    }

    deleteAdmin(objectId: string): Observable<UserPermission[]> {
        return this.http.delete<UserPermission[]>(`/api/security/adminUser/${objectId}`);
    }

    loadAdmins(): Observable<UserPermission[]> {
        return this.http.get<UserPermission[]>('/api/security/adminUser');
    }
}
